// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/buddy/valorem/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/buddy/valorem/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bespoke-js": () => import("/buddy/valorem/src/pages/bespoke.js" /* webpackChunkName: "component---src-pages-bespoke-js" */),
  "component---src-pages-brands-js": () => import("/buddy/valorem/src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-brands-boadicea-js": () => import("/buddy/valorem/src/pages/brands/boadicea.js" /* webpackChunkName: "component---src-pages-brands-boadicea-js" */),
  "component---src-pages-brands-horatio-js": () => import("/buddy/valorem/src/pages/brands/horatio.js" /* webpackChunkName: "component---src-pages-brands-horatio-js" */),
  "component---src-pages-brands-kolo-js": () => import("/buddy/valorem/src/pages/brands/kolo.js" /* webpackChunkName: "component---src-pages-brands-kolo-js" */),
  "component---src-pages-brands-nod-js": () => import("/buddy/valorem/src/pages/brands/nod.js" /* webpackChunkName: "component---src-pages-brands-nod-js" */),
  "component---src-pages-brands-quintesscent-js": () => import("/buddy/valorem/src/pages/brands/quintesscent.js" /* webpackChunkName: "component---src-pages-brands-quintesscent-js" */),
  "component---src-pages-brands-shl-js": () => import("/buddy/valorem/src/pages/brands/shl.js" /* webpackChunkName: "component---src-pages-brands-shl-js" */),
  "component---src-pages-contact-js": () => import("/buddy/valorem/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("/buddy/valorem/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("/buddy/valorem/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/buddy/valorem/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

